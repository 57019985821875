import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from "@mui/material";
import FormModal from "./FormModal";
import { API_BASE_URL } from "../config/api";

const Withdrawals = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [filteredWithdrawals, setFilteredWithdrawals] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [editingWithdrawal, setEditingWithdrawal] = useState(null);

  useEffect(() => {
    fetchWithdrawals();
  }, []);

  useEffect(() => {
    const filtered = withdrawals.filter((withdrawal) =>
      Object.values(withdrawal)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredWithdrawals(filtered);
    setPage(0);
  }, [searchTerm, withdrawals]);

  const fetchWithdrawals = async () => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await axios.get(`${API_BASE_URL}/withdrawals`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWithdrawals(response.data);
      setFilteredWithdrawals(response.data);
    } catch (error) {
      console.error("Error fetching withdrawals:", error);
    }
  };

  const handleEdit = async (withdrawal) => {
    const token = localStorage.getItem("adminToken");
    try {
      await axios.put(
        `${API_BASE_URL}/withdrawals/${withdrawal._id}`,
        withdrawal,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchWithdrawals();
    } catch (error) {
      console.error("Error editing withdrawal:", error);
    }
  };

  const handleApprove = async (id) => {
    const token = localStorage.getItem("adminToken");
    try {
      await axios.put(
        `${API_BASE_URL}/withdrawals/${id}`,
        { status: "approved" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchWithdrawals();
    } catch (error) {
      console.error("Error approving withdrawal:", error);
    }
  };

  const handleReject = async (id) => {
    const token = localStorage.getItem("adminToken");
    try {
      await axios.put(
        `${API_BASE_URL}/withdrawals/${id}`,
        { status: "rejected" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchWithdrawals();
    } catch (error) {
      console.error("Error rejecting withdrawal:", error);
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("adminToken");
    try {
      await axios.delete(`${API_BASE_URL}/withdrawals/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchWithdrawals();
    } catch (error) {
      console.error("Error deleting withdrawal:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentWithdrawals = filteredWithdrawals.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div>
      <h2>Withdrawals</h2>
      <TextField
        label="Search Withdrawals"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Amount to Withdraw</TableCell>
              <TableCell>Wallet Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentWithdrawals.map((withdrawal) => (
              <TableRow key={withdrawal._id}>
                <TableCell>{withdrawal.userID}</TableCell>
                <TableCell>{withdrawal.balance}</TableCell>
                <TableCell>{withdrawal.toWithdraw}</TableCell>
                <TableCell>{withdrawal.walletType}</TableCell>
                <TableCell>{withdrawal.address}</TableCell>
                <TableCell>{withdrawal.status}</TableCell>
                <TableCell>{withdrawal.reason}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setEditingWithdrawal(withdrawal);
                      setShowModal(true);
                    }}
                    style={{ marginRight: "8px" }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleApprove(withdrawal._id)}
                    disabled={withdrawal.status === "approved" || withdrawal.status === "rejected"}
                    style={{ marginRight: "8px" }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => handleReject(withdrawal._id)}
                    disabled={withdrawal.status === "approved" || withdrawal.status === "rejected"}
                    style={{ marginRight: "8px" }}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(withdrawal._id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredWithdrawals.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <FormModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setEditingWithdrawal(null);
        }}
        onSubmit={handleEdit}
        initialData={editingWithdrawal}
        fields={["userID", "balance", "toWithdraw", "walletType", "address", "status", "reason"]}
      />
    </div>
  );
};

export default Withdrawals;