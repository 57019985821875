import React, { useState, useEffect } from "react";
import axios from "axios";
import { 
  Button, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Stack
} from "@mui/material";
import { API_BASE_URL } from "../config/api";

const AdminSettings = () => {
  const [settings, setSettings] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editForm, setEditForm] = useState({
    admin: "admin",
    ref: "",
    cur: "",
    paychannel: "",
    bonus: "",
    minimum: "",
    botstat: "",
    withstat: "",
    subwallet: "",
    MKEY: "",
    MID: "",
    channels: "",
    walletTypes: "",
    contact_admin: "",
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await axios.get(`${API_BASE_URL}/admin-settings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Take the first settings object from the response
      setSettings(response.data[0]);
    } catch (error) {
      console.error("Error fetching admin settings:", error);
    }
  };

  const handleEdit = () => {
    setEditForm({
      ...settings,
      channels: settings.channels.join(', '),
      walletTypes: settings.walletTypes?.join(', ') || "BKASH, NAGAD"
    });
    setOpenDialog(true);
  };

  const handleSave = async () => {
    const token = localStorage.getItem("adminToken");
    try {
      const processedData = {
        ...editForm,
        channels: editForm.channels.split(',').map(item => item.trim()),
        walletTypes: editForm.walletTypes.split(',').map(item => item.trim())
      };

      await axios.put(`${API_BASE_URL}/admin-settings/${settings._id}`, processedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchSettings();
      setOpenDialog(false);
    } catch (error) {
      console.error("Error updating admin settings:", error);
    }
  };

  const handleChange = (e) => {
    setEditForm({
      ...editForm,
      [e.target.name]: e.target.value
    });
  };

  if (!settings) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Admin Settings</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>Admin</TableCell> */}
              <TableCell>Refer Reward</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Pay Channel</TableCell>
              <TableCell>Bonus</TableCell>
              <TableCell>Minimum</TableCell>
              <TableCell>Bot Status</TableCell>
              <TableCell>Withdrawal Status</TableCell>
              {/* <TableCell>Sub Wallet</TableCell>
              <TableCell>MKEY</TableCell>
              <TableCell>MID</TableCell> */}
              <TableCell>Channels</TableCell>
              <TableCell>Contact Admin</TableCell>
              <TableCell>Wallet Types</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {/* <TableCell>{settings.admin}</TableCell> */}
              <TableCell>{settings.ref}</TableCell>
              <TableCell>{settings.cur}</TableCell>
              <TableCell>{settings.paychannel}</TableCell>
              <TableCell>{settings.bonus}</TableCell>
              <TableCell>{settings.minimum}</TableCell>
              <TableCell>{settings.botstat}</TableCell>
              <TableCell>{settings.withstat}</TableCell>
              {/* <TableCell>{settings.subwallet}</TableCell>
              <TableCell>{settings.MKEY}</TableCell>
              <TableCell>{settings.MID}</TableCell> */}
              <TableCell>
                <Stack direction="row" spacing={1}>
                  {settings.channels.map((channel, index) => (
                    <Chip key={index} label={channel} size="small" />
                  ))}
                </Stack>
              </TableCell>
                  <TableCell>{settings.contact_admin}</TableCell>
              <TableCell>
                <Stack direction="row" spacing={1}>
                  {settings.walletTypes?.map((wallet, index) => (
                    <Chip key={index} label={wallet} size="small" color="primary" />
                  ))}
                </Stack>
              </TableCell>
              <TableCell>
                <Button variant="contained" color="primary" onClick={handleEdit}>
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Edit Admin Settings</DialogTitle>
        <DialogContent>
          {/* <TextField
            name="admin"
            label="Admin"
            value={editForm.admin}
            onChange={handleChange}
            fullWidth
            margin="normal"
          /> */}
          <TextField
            name="ref"
            label="Refer Reward"
            value={editForm.ref}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="cur"
            label="Currency"
            value={editForm.cur}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="paychannel"
            label="Pay Channel"
            value={editForm.paychannel}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="bonus"
            label="Bonus"
            value={editForm.bonus}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            name="minimum"
            label="Minimum"
            value={editForm.minimum}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            name="botstat"
            label="Bot Status"
            value={editForm.botstat}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="withstat"
            label="Withdrawal Status"
            value={editForm.withstat}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          {/* <TextField
            name="subwallet"
            label="Sub Wallet"
            value={editForm.subwallet}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="MKEY"
            label="MKEY"
            value={editForm.MKEY}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="MID"
            label="MID"
            value={editForm.MID}
            onChange={handleChange}
            fullWidth
            margin="normal"
          /> */}
          <TextField
            name="channels"
            label="Channels (comma-separated)"
            value={editForm.channels}
            onChange={handleChange}
            fullWidth
            margin="normal"
            helperText="Enter channels separated by commas"
          />

          <TextField
            name="contact_admin"
            label="Contact"
            value={editForm.contact_admin}
            onChange={handleChange}
            fullWidth
            margin="normal"
          /> 
          <TextField
            name="walletTypes"
            label="Wallet Types (comma-separated)"
            value={editForm.walletTypes}
            onChange={handleChange}
            fullWidth
            margin="normal"
            helperText="Enter wallet types separated by commas"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminSettings;